<template>
  <div class="operateLog c-bg-white">
    <!-- 搜索 -->
    <search-form @search="search"></search-form>
    <log-table :list="logList"></log-table>
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
  import searchForm from './components/search-form';
  import logTable from './components/log-table';
  import pagination from '@/mixins/pagination';
  import service from "./api";
  export default {
    components:{ searchForm, logTable},
    mixins:[pagination],
    data(){
      return {
        logList: [],
        page:{},
        searchData: {}
      }
    },
    methods:{
      init(){
        let initParams = { pageNum: 1 };
        this.getList(initParams);
      },
      getList(params) {
        // 切换当前页码
        let { pageNum = 1 } = params;
        this.currentPage = pageNum;
        return service.getList(params).then(res => {
          let list = res.list;
          this.page = res.page;
          list.forEach((item, index) => {
            item.tempId = (params.pageNum - 1) * this.page.pageSize + index + 1;
          });
          this.logList = list;
        });
      },
      search(data) {
        let params = Object.assign(data, { pageNum: 1 });
        this.searchData = data;
        return this.getList(params);
      }
    },
    created() {
      this.init()
    }
  }
</script>

<style lang="scss" scoped>
.operateLog{
  padding: 20px;
  overflow: hidden;
}
</style>
